/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';


export interface ExpoExhibitorOverviewDto { 
    id?: string;
    shortKey?: string;
    name?: string;
    company?: string;
    description?: string;
    descriptionLong?: string;
    category?: number;
    label?: string;
    website?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    backgroundImage?: string;
    posterA?: string;
    posterB?: string;
    boothIcon?: string;
    avatar?: string;
    sequence?: number;
    chatOnline?: boolean;
    singleChatOnline?: boolean;
    videoOnline?: boolean;
    shortLabel?: string;
    exhibitorGroupId?: number;
    liveStreamActive?: boolean;
    liveStreamType?: StreamType;
    liveStreamId?: string;
    tags?: Array<string>;
    rowVersion?: number;
    boothPresentationEnabled?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoExhibitorOverviewDto.
 */
export namespace ExpoExhibitorOverviewDto {
    /**
     * All properties of ExpoExhibitorOverviewDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        company = 'company',
        description = 'description',
        descriptionLong = 'descriptionLong',
        category = 'category',
        label = 'label',
        website = 'website',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        backgroundImage = 'backgroundImage',
        posterA = 'posterA',
        posterB = 'posterB',
        boothIcon = 'boothIcon',
        avatar = 'avatar',
        sequence = 'sequence',
        chatOnline = 'chatOnline',
        singleChatOnline = 'singleChatOnline',
        videoOnline = 'videoOnline',
        shortLabel = 'shortLabel',
        exhibitorGroupId = 'exhibitorGroupId',
        liveStreamActive = 'liveStreamActive',
        liveStreamType = 'liveStreamType',
        liveStreamId = 'liveStreamId',
        tags = 'tags',
        rowVersion = 'rowVersion',
        boothPresentationEnabled = 'boothPresentationEnabled'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoExhibitorOverviewDto.
    */
    export const ModelValidators: {[K in keyof ExpoExhibitorOverviewDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
        ],
        company: [
        ],
        description: [
        ],
        descriptionLong: [
        ],
        category: [
        ],
        label: [
        ],
        website: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        backgroundImage: [
        ],
        posterA: [
        ],
        posterB: [
        ],
        boothIcon: [
        ],
        avatar: [
        ],
        sequence: [
        ],
        chatOnline: [
        ],
        singleChatOnline: [
        ],
        videoOnline: [
        ],
        shortLabel: [
        ],
        exhibitorGroupId: [
        ],
        liveStreamActive: [
        ],
        liveStreamType: [
        ],
        liveStreamId: [
        ],
        tags: [
        ],
        rowVersion: [
        ],
        boothPresentationEnabled: [
        ],
    };

    /**
    * The FormControlFactory for ExpoExhibitorOverviewDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoExhibitorOverviewDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoExhibitorOverviewDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoExhibitorOverviewDto = {
            id: null,
            shortKey: null,
            name: null,
            company: null,
            description: null,
            descriptionLong: null,
            category: null,
            label: null,
            website: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            backgroundImage: null,
            posterA: null,
            posterB: null,
            boothIcon: null,
            avatar: null,
            sequence: null,
            chatOnline: null,
            singleChatOnline: null,
            videoOnline: null,
            shortLabel: null,
            exhibitorGroupId: null,
            liveStreamActive: null,
            liveStreamType: null,
            liveStreamId: null,
            tags: null,
            rowVersion: null,
            boothPresentationEnabled: null,
          }
        ) {
            super(model, ExpoExhibitorOverviewDto.ModelValidators);
        }
    }

}


