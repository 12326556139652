/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface LinkListDto { 
    id?: string;
    sequence?: number;
    description?: string;
    url?: string;
    inActive?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of LinkListDto.
 */
export namespace LinkListDto {
    /**
     * All properties of LinkListDto.
     */
    export enum Properties {
        id = 'id',
        sequence = 'sequence',
        description = 'description',
        url = 'url',
        inActive = 'inActive'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of LinkListDto.
    */
    export const ModelValidators: {[K in keyof LinkListDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        sequence: [
        ],
        description: [
        ],
        url: [
        ],
        inActive: [
        ],
    };

    /**
    * The FormControlFactory for LinkListDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<LinkListDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for LinkListDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: LinkListDto = {
            id: null,
            sequence: null,
            description: null,
            url: null,
            inActive: null,
          }
        ) {
            super(model, LinkListDto.ModelValidators);
        }
    }

}


