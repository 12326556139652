/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type ExhibitorContactMode = 0 | 1 | 10 | 11 | -1;

export const ExhibitorContactMode = {
    NUMBER_0: 0 as ExhibitorContactMode,
    NUMBER_1: 1 as ExhibitorContactMode,
    NUMBER_10: 10 as ExhibitorContactMode,
    NUMBER_11: 11 as ExhibitorContactMode,
    NUMBER_MINUS_1: -1 as ExhibitorContactMode
};

