/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



/**
 * Class ChatMessageSendDto.
 */
export interface ChatMessageSendDto { 
    /**
     * Gets or sets the chat identifier.
     */
    chatId?: string;
    /**
     * Gets or sets the name of the sender.
     */
    senderName?: string;
    /**
     * Gets or sets the message.
     */
    message?: string;
    /**
     * Gets or sets a value indicating whether the sender is a team member.
     */
    senderIsTeam?: boolean;
    /**
     * Gets or sets the timestamp.
     */
    timestamp?: Date;
}

/**
 * Namespace for property- and property-value-enumerations of ChatMessageSendDto.
 */
export namespace ChatMessageSendDto {
    /**
     * All properties of ChatMessageSendDto.
     */
    export enum Properties {
        /**
         * Gets or sets the chat identifier.
         */
        chatId = 'chatId',
        /**
         * Gets or sets the name of the sender.
         */
        senderName = 'senderName',
        /**
         * Gets or sets the message.
         */
        message = 'message',
        /**
         * Gets or sets a value indicating whether the sender is a team member.
         */
        senderIsTeam = 'senderIsTeam',
        /**
         * Gets or sets the timestamp.
         */
        timestamp = 'timestamp'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ChatMessageSendDto.
    */
    export const ModelValidators: {[K in keyof ChatMessageSendDto]: [string, ValidatorFn][]} = {
        chatId: [
        ],
        senderName: [
        ],
        message: [
        ],
        senderIsTeam: [
        ],
        timestamp: [
        ],
    };

    /**
    * The FormControlFactory for ChatMessageSendDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ChatMessageSendDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ChatMessageSendDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ChatMessageSendDto = {
            chatId: null,
            senderName: null,
            message: null,
            senderIsTeam: null,
            timestamp: null,
          }
        ) {
            super(model, ChatMessageSendDto.ModelValidators);
        }
    }

}


