export const environment = {
  production: true,
  apiurl: 'api/',
  manageurl: 'https://manage.virtualexpo.info',
  chatHub: 'api/chat',
  webChatHub: 'api/webchat',
  systemNotificationHub: 'api/signals',
  chatChannelHub: 'api/chatchannel',
  webChatSource: 'api/web/chatclient/$$EXPOID$$/$$CHATID$$?access-token=$$TOKEN$$',
  zoomSource: 'api/web/zoom/$$EXPOID$$/$$STAGEID$$/$$ENTRYID$$?access-token=$$TOKEN$$&ngsw-bypass=true',
  webexSource: 'api/web/webex/$$EXPOID$$/$$STAGEID$$/$$ENTRYID$$?access-token=$$TOKEN$$&ngsw-bypass=true',
  zoomSourceBooth: 'api/web/zoom-booth/$$EXPOID$$/$$EXHIBITORID$$/$$ENTRYID$$?access-token=$$TOKEN$$&ngsw-bypass=true',
  webexSourceBooth: 'api/web/webex-booth/$$EXPOID$$/$$EXHIBITORID$$/$$ENTRYID$$?access-token=$$TOKEN$$&ngsw-bypass=true',
  webexSourceBoothSip: 'api/web/webex-sip/$$EXPOID$$/$$EXHIBITORID$$/$$ENTRYID$$?access-token=$$TOKEN$$&ngsw-bypass=true',
  authDomain: 'https://virtualexpo.info',
  videoUrl: 'jitsi.dotenterprise.com',
  API_BASE_PATH: '.',
  versionCheckURL : 'version.json',
  versionCheckInterval: 5,
  cdnURL: 'https://cdn.virtualexpo.info',
  downloadURL: 'https://cdn.virtualexpo.info',
  assetURL: 'img/assets',
  googleAnalyticsId: 'UA-158948219-1',
  enable3D: false,
  showLogin: false,
  baseDomain: 'virtualexpo.info',
  orgaName: 'dotEnterprise VirtualExpo',
  orgaLogo: '/assets/ve-logo.svg',
  sentryEnabled: true,
  sentryDsn: 'https://e205b7b84c01470a9c81847ca74c0239@debug.nethotline.io/1',
  showMissingTranslations: false,
  agoraId: '7619ac48cb274b109606888a71d9a8a0',
  matomoServer: 'matomo.dotenterprise.com',
  matomoSiteId: '1',
  freshChatKey: 'e55c6820-a00b-4bf5-a97a-165f862eee03',
  freshChatHost: 'https://wchat.eu.freshchat.com',
  jitsiLoader: 'https://jitsi.dotenterprise.com/external_api.js',
  matomoActive: true,
  googleActive: true,
  freshChatActive: false,
  jitsiActive: true,
  useServiceWorker: true,
  useHash: false,
  elementsIntegration: false,
  permissionsVersion: 2,
  imageProxyPrefix: 'https://virtualexpo.info/proxy/',
  timeStamp: '2024-04-03 08:15:42 UTC',
  testDomain: 'dev.virtualexpo.info'
};
