/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type CalendarEntryType = 100 | 200 | 500 | 600 | 700;

export const CalendarEntryType = {
    NUMBER_100: 100 as CalendarEntryType,
    NUMBER_200: 200 as CalendarEntryType,
    NUMBER_500: 500 as CalendarEntryType,
    NUMBER_600: 600 as CalendarEntryType,
    NUMBER_700: 700 as CalendarEntryType
};

