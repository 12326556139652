/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ActionButton } from './actionButton';
import { InventoryItemType } from './inventoryItemType';
import { SidebarMode } from './sidebarMode';
import { ExpoButtonPosition } from './expoButtonPosition';
import { OverviewMode } from './overviewMode';


export interface ExpoDesignDto { 
    font_color?: string;
    title_color?: string;
    background_color?: string;
    modal_background_color?: string;
    box_background_color?: string;
    box_font_color?: string;
    button_background_color?: string;
    button_font_color?: string;
    button_size_top?: string;
    button_size_bottom?: string;
    button_size_booth?: string;
    expo_intro_separate?: boolean;
    expo_intro_mode?: number;
    expo_intro_image?: string;
    expo_intro_padding_small?: boolean;
    expo_intro_text_wide?: boolean;
    expo_intro_text_frame?: boolean;
    expo_desc_mode?: number;
    expo_desc_image?: string;
    expo_desc_padding_small?: boolean;
    expo_desc_text_wide?: boolean;
    expo_desc_text_frame?: boolean;
    backgroundImage?: string;
    showStagesOnLanding?: boolean;
    showStagesSide?: boolean;
    stagesAfterBoothsOnLanding?: boolean;
    groupingHideUngroupedLanding?: boolean;
    groupingUngroupedAfterGrouped?: boolean;
    groupingHideUngroupedSidebar?: boolean;
    overviewMode?: OverviewMode;
    sidebarMode?: SidebarMode;
    hideBoothNextPrev?: boolean;
    landingPage?: string;
    showLogo?: number;
    hideExpoName?: boolean;
    hideOverviewCalendar?: boolean;
    hideEnterExhibitionButton?: boolean;
    hideCalendarButton?: boolean;
    expoButtonPosition?: ExpoButtonPosition;
    expoButtonSize?: string;
    expoStream?: string;
    expoStreamType?: InventoryItemType;
    expoStreamPosition?: number;
    showLogoOnBooth?: number;
    showExhibitorLogoOnBooth?: number;
    actionButtonModeTop?: number;
    actionButtonsTop?: Array<ActionButton>;
    actionButtonModeBottom?: number;
    actionButtonsBottom?: Array<ActionButton>;
    surveyDefinition?: string;
    disableVideoAutoplay?: boolean;
    hideExhibitorsOnLanding?: boolean;
    boothIconFontSize?: number;
    introFontSize?: number;
    defaultFontSize?: number;
    labelHome?: string;
    labelStages?: string;
    labelExhibitors?: string;
    exhibitorButtonMode?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoDesignDto.
 */
export namespace ExpoDesignDto {
    /**
     * All properties of ExpoDesignDto.
     */
    export enum Properties {
        font_color = 'font_color',
        title_color = 'title_color',
        background_color = 'background_color',
        modal_background_color = 'modal_background_color',
        box_background_color = 'box_background_color',
        box_font_color = 'box_font_color',
        button_background_color = 'button_background_color',
        button_font_color = 'button_font_color',
        button_size_top = 'button_size_top',
        button_size_bottom = 'button_size_bottom',
        button_size_booth = 'button_size_booth',
        expo_intro_separate = 'expo_intro_separate',
        expo_intro_mode = 'expo_intro_mode',
        expo_intro_image = 'expo_intro_image',
        expo_intro_padding_small = 'expo_intro_padding_small',
        expo_intro_text_wide = 'expo_intro_text_wide',
        expo_intro_text_frame = 'expo_intro_text_frame',
        expo_desc_mode = 'expo_desc_mode',
        expo_desc_image = 'expo_desc_image',
        expo_desc_padding_small = 'expo_desc_padding_small',
        expo_desc_text_wide = 'expo_desc_text_wide',
        expo_desc_text_frame = 'expo_desc_text_frame',
        backgroundImage = 'backgroundImage',
        showStagesOnLanding = 'showStagesOnLanding',
        showStagesSide = 'showStagesSide',
        stagesAfterBoothsOnLanding = 'stagesAfterBoothsOnLanding',
        groupingHideUngroupedLanding = 'groupingHideUngroupedLanding',
        groupingUngroupedAfterGrouped = 'groupingUngroupedAfterGrouped',
        groupingHideUngroupedSidebar = 'groupingHideUngroupedSidebar',
        overviewMode = 'overviewMode',
        sidebarMode = 'sidebarMode',
        hideBoothNextPrev = 'hideBoothNextPrev',
        landingPage = 'landingPage',
        showLogo = 'showLogo',
        hideExpoName = 'hideExpoName',
        hideOverviewCalendar = 'hideOverviewCalendar',
        hideEnterExhibitionButton = 'hideEnterExhibitionButton',
        hideCalendarButton = 'hideCalendarButton',
        expoButtonPosition = 'expoButtonPosition',
        expoButtonSize = 'expoButtonSize',
        expoStream = 'expoStream',
        expoStreamType = 'expoStreamType',
        expoStreamPosition = 'expoStreamPosition',
        showLogoOnBooth = 'showLogoOnBooth',
        showExhibitorLogoOnBooth = 'showExhibitorLogoOnBooth',
        actionButtonModeTop = 'actionButtonModeTop',
        actionButtonsTop = 'actionButtonsTop',
        actionButtonModeBottom = 'actionButtonModeBottom',
        actionButtonsBottom = 'actionButtonsBottom',
        surveyDefinition = 'surveyDefinition',
        disableVideoAutoplay = 'disableVideoAutoplay',
        hideExhibitorsOnLanding = 'hideExhibitorsOnLanding',
        boothIconFontSize = 'boothIconFontSize',
        introFontSize = 'introFontSize',
        defaultFontSize = 'defaultFontSize',
        labelHome = 'labelHome',
        labelStages = 'labelStages',
        labelExhibitors = 'labelExhibitors',
        exhibitorButtonMode = 'exhibitorButtonMode'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoDesignDto.
    */
    export const ModelValidators: {[K in keyof ExpoDesignDto]: [string, ValidatorFn][]} = {
        font_color: [
        ],
        title_color: [
        ],
        background_color: [
        ],
        modal_background_color: [
        ],
        box_background_color: [
        ],
        box_font_color: [
        ],
        button_background_color: [
        ],
        button_font_color: [
        ],
        button_size_top: [
        ],
        button_size_bottom: [
        ],
        button_size_booth: [
        ],
        expo_intro_separate: [
        ],
        expo_intro_mode: [
        ],
        expo_intro_image: [
        ],
        expo_intro_padding_small: [
        ],
        expo_intro_text_wide: [
        ],
        expo_intro_text_frame: [
        ],
        expo_desc_mode: [
        ],
        expo_desc_image: [
        ],
        expo_desc_padding_small: [
        ],
        expo_desc_text_wide: [
        ],
        expo_desc_text_frame: [
        ],
        backgroundImage: [
        ],
        showStagesOnLanding: [
        ],
        showStagesSide: [
        ],
        stagesAfterBoothsOnLanding: [
        ],
        groupingHideUngroupedLanding: [
        ],
        groupingUngroupedAfterGrouped: [
        ],
        groupingHideUngroupedSidebar: [
        ],
        overviewMode: [
        ],
        sidebarMode: [
        ],
        hideBoothNextPrev: [
        ],
        landingPage: [
        ],
        showLogo: [
        ],
        hideExpoName: [
        ],
        hideOverviewCalendar: [
        ],
        hideEnterExhibitionButton: [
        ],
        hideCalendarButton: [
        ],
        expoButtonPosition: [
        ],
        expoButtonSize: [
        ],
        expoStream: [
        ],
        expoStreamType: [
        ],
        expoStreamPosition: [
        ],
        showLogoOnBooth: [
        ],
        showExhibitorLogoOnBooth: [
        ],
        actionButtonModeTop: [
        ],
        actionButtonsTop: [
        ],
        actionButtonModeBottom: [
        ],
        actionButtonsBottom: [
        ],
        surveyDefinition: [
        ],
        disableVideoAutoplay: [
        ],
        hideExhibitorsOnLanding: [
        ],
        boothIconFontSize: [
        ],
        introFontSize: [
        ],
        defaultFontSize: [
        ],
        labelHome: [
        ],
        labelStages: [
        ],
        labelExhibitors: [
        ],
        exhibitorButtonMode: [
        ],
    };

    /**
    * The FormControlFactory for ExpoDesignDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoDesignDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoDesignDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoDesignDto = {
            font_color: null,
            title_color: null,
            background_color: null,
            modal_background_color: null,
            box_background_color: null,
            box_font_color: null,
            button_background_color: null,
            button_font_color: null,
            button_size_top: null,
            button_size_bottom: null,
            button_size_booth: null,
            expo_intro_separate: null,
            expo_intro_mode: null,
            expo_intro_image: null,
            expo_intro_padding_small: null,
            expo_intro_text_wide: null,
            expo_intro_text_frame: null,
            expo_desc_mode: null,
            expo_desc_image: null,
            expo_desc_padding_small: null,
            expo_desc_text_wide: null,
            expo_desc_text_frame: null,
            backgroundImage: null,
            showStagesOnLanding: null,
            showStagesSide: null,
            stagesAfterBoothsOnLanding: null,
            groupingHideUngroupedLanding: null,
            groupingUngroupedAfterGrouped: null,
            groupingHideUngroupedSidebar: null,
            overviewMode: null,
            sidebarMode: null,
            hideBoothNextPrev: null,
            landingPage: null,
            showLogo: null,
            hideExpoName: null,
            hideOverviewCalendar: null,
            hideEnterExhibitionButton: null,
            hideCalendarButton: null,
            expoButtonPosition: null,
            expoButtonSize: null,
            expoStream: null,
            expoStreamType: null,
            expoStreamPosition: null,
            showLogoOnBooth: null,
            showExhibitorLogoOnBooth: null,
            actionButtonModeTop: null,
            actionButtonsTop: null,
            actionButtonModeBottom: null,
            actionButtonsBottom: null,
            surveyDefinition: null,
            disableVideoAutoplay: null,
            hideExhibitorsOnLanding: null,
            boothIconFontSize: null,
            introFontSize: null,
            defaultFontSize: null,
            labelHome: null,
            labelStages: null,
            labelExhibitors: null,
            exhibitorButtonMode: null,
          }
        ) {
            super(model, ExpoDesignDto.ModelValidators);
        }
    }

}


