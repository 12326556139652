/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { BookingResultState } from './bookingResultState';
import { AvailabilityDto } from './availabilityDto';


export interface AvailableBookingResult { 
    state?: BookingResultState;
    slots?: Array<AvailabilityDto>;
}

/**
 * Namespace for property- and property-value-enumerations of AvailableBookingResult.
 */
export namespace AvailableBookingResult {
    /**
     * All properties of AvailableBookingResult.
     */
    export enum Properties {
        state = 'state',
        slots = 'slots'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of AvailableBookingResult.
    */
    export const ModelValidators: {[K in keyof AvailableBookingResult]: [string, ValidatorFn][]} = {
        state: [
        ],
        slots: [
        ],
    };

    /**
    * The FormControlFactory for AvailableBookingResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<AvailableBookingResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for AvailableBookingResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: AvailableBookingResult = {
            state: null,
            slots: null,
          }
        ) {
            super(model, AvailableBookingResult.ModelValidators);
        }
    }

}


