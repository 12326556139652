/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { InventoryItemType } from './inventoryItemType';


export interface StageReferenceDto { 
    id?: string;
    shortKey?: string;
    name?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    current?: number;
    vodType?: InventoryItemType;
    vodSource?: string;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of StageReferenceDto.
 */
export namespace StageReferenceDto {
    /**
     * All properties of StageReferenceDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        current = 'current',
        vodType = 'vodType',
        vodSource = 'vodSource',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StageReferenceDto.
    */
    export const ModelValidators: {[K in keyof StageReferenceDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        current: [
        ],
        vodType: [
        ],
        vodSource: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for StageReferenceDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<StageReferenceDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for StageReferenceDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StageReferenceDto = {
            id: null,
            shortKey: null,
            name: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            current: null,
            vodType: null,
            vodSource: null,
            rowVersion: null,
          }
        ) {
            super(model, StageReferenceDto.ModelValidators);
        }
    }

}


