/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



/**
 * Login result data
 */
export interface LoginResultDto { 
    /**
     * The generated JWT Token string
     */
    token?: string;
    /**
     * Token lifetime in seconds
     */
    lifetime?: number;
    /**
     * Gets or sets the identifier.
     */
    id?: number;
}

/**
 * Namespace for property- and property-value-enumerations of LoginResultDto.
 */
export namespace LoginResultDto {
    /**
     * All properties of LoginResultDto.
     */
    export enum Properties {
        /**
         * The generated JWT Token string
         */
        token = 'token',
        /**
         * Token lifetime in seconds
         */
        lifetime = 'lifetime',
        /**
         * Gets or sets the identifier.
         */
        id = 'id'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of LoginResultDto.
    */
    export const ModelValidators: {[K in keyof LoginResultDto]: [string, ValidatorFn][]} = {
        token: [
        ],
        lifetime: [
        ],
        id: [
        ],
    };

    /**
    * The FormControlFactory for LoginResultDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<LoginResultDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for LoginResultDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: LoginResultDto = {
            token: null,
            lifetime: null,
            id: null,
          }
        ) {
            super(model, LoginResultDto.ModelValidators);
        }
    }

}


