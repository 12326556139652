/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface BookSlotDto { 
    rowId?: number;
    start?: Date;
    interval?: number;
    email?: string;
    name?: string;
    comment?: string;
}

/**
 * Namespace for property- and property-value-enumerations of BookSlotDto.
 */
export namespace BookSlotDto {
    /**
     * All properties of BookSlotDto.
     */
    export enum Properties {
        rowId = 'rowId',
        start = 'start',
        interval = 'interval',
        email = 'email',
        name = 'name',
        comment = 'comment'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of BookSlotDto.
    */
    export const ModelValidators: {[K in keyof BookSlotDto]: [string, ValidatorFn][]} = {
        rowId: [
        ],
        start: [
        ],
        interval: [
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        name: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        comment: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
    };

    /**
    * The FormControlFactory for BookSlotDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<BookSlotDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for BookSlotDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: BookSlotDto = {
            rowId: null,
            start: null,
            interval: null,
            email: null,
            name: null,
            comment: null,
          }
        ) {
            super(model, BookSlotDto.ModelValidators);
        }
    }

}


