/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StageOverviewDto } from './stageOverviewDto';
import { ExpoExhibitorOverviewDto } from './expoExhibitorOverviewDto';


export interface EnterExpoDto { 
    expo?: string;
    stages?: Array<StageOverviewDto>;
    exhibitors?: Array<ExpoExhibitorOverviewDto>;
}

/**
 * Namespace for property- and property-value-enumerations of EnterExpoDto.
 */
export namespace EnterExpoDto {
    /**
     * All properties of EnterExpoDto.
     */
    export enum Properties {
        expo = 'expo',
        stages = 'stages',
        exhibitors = 'exhibitors'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of EnterExpoDto.
    */
    export const ModelValidators: {[K in keyof EnterExpoDto]: [string, ValidatorFn][]} = {
        expo: [
        ],
        stages: [
        ],
        exhibitors: [
        ],
    };

    /**
    * The FormControlFactory for EnterExpoDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<EnterExpoDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for EnterExpoDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: EnterExpoDto = {
            expo: null,
            stages: null,
            exhibitors: null,
          }
        ) {
            super(model, EnterExpoDto.ModelValidators);
        }
    }

}


