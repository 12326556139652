/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { AccessMode } from './accessMode';


/**
 * Class ExpoSimpleListItemDto.
 */
export interface ExpoSimpleListItemDto { 
    /**
     * Gets the identifier.
     */
    id?: string;
    /**
     * Gets the short key.
     */
    shortKey?: string;
    /**
     * Gets the name.
     */
    name?: string;
    /**
     * Gets the description.
     */
    description?: string;
    /**
     * Gets the date end.
     */
    dateStart?: Date;
    dateEnd?: Date;
    /**
     * Gets the logo.
     */
    logo?: string;
    /**
     * Gets the logo square.
     */
    logoSquare?: string;
    /**
     * Gets the logo wide.
     */
    logoWide?: string;
    accessMode?: AccessMode;
    /**
     * Gets or sets a value indicating whether this instance is preview.
     */
    isPreview?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoSimpleListItemDto.
 */
export namespace ExpoSimpleListItemDto {
    /**
     * All properties of ExpoSimpleListItemDto.
     */
    export enum Properties {
        /**
         * Gets the identifier.
         */
        id = 'id',
        /**
         * Gets the short key.
         */
        shortKey = 'shortKey',
        /**
         * Gets the name.
         */
        name = 'name',
        /**
         * Gets the description.
         */
        description = 'description',
        /**
         * Gets the date end.
         */
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        /**
         * Gets the logo.
         */
        logo = 'logo',
        /**
         * Gets the logo square.
         */
        logoSquare = 'logoSquare',
        /**
         * Gets the logo wide.
         */
        logoWide = 'logoWide',
        accessMode = 'accessMode',
        /**
         * Gets or sets a value indicating whether this instance is preview.
         */
        isPreview = 'isPreview'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoSimpleListItemDto.
    */
    export const ModelValidators: {[K in keyof ExpoSimpleListItemDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
        ],
        description: [
        ],
        dateStart: [
        ],
        dateEnd: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        accessMode: [
        ],
        isPreview: [
        ],
    };

    /**
    * The FormControlFactory for ExpoSimpleListItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoSimpleListItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoSimpleListItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoSimpleListItemDto = {
            id: null,
            shortKey: null,
            name: null,
            description: null,
            dateStart: null,
            dateEnd: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            accessMode: null,
            isPreview: null,
          }
        ) {
            super(model, ExpoSimpleListItemDto.ModelValidators);
        }
    }

}


