/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface SurveyResult { 
    rowid?: number;
    expoId: string;
    results: string;
}

/**
 * Namespace for property- and property-value-enumerations of SurveyResult.
 */
export namespace SurveyResult {
    /**
     * All properties of SurveyResult.
     */
    export enum Properties {
        rowid = 'rowid',
        expoId = 'expoId',
        results = 'results'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of SurveyResult.
    */
    export const ModelValidators: {[K in keyof SurveyResult]: [string, ValidatorFn][]} = {
        rowid: [
        ],
        expoId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(1)],
        ],
        results: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(1)],
        ],
    };

    /**
    * The FormControlFactory for SurveyResult.
    */
    export class FormControlFactory extends BaseFormControlFactory<SurveyResult> {

        /**
         * Constructor.
         *
         * @param model An existing model for SurveyResult.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: SurveyResult = {
            rowid: null,
            expoId: null,
            results: null,
          }
        ) {
            super(model, SurveyResult.ModelValidators);
        }
    }

}


