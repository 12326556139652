/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StageReferenceDto } from './stageReferenceDto';
import { StreamType } from './streamType';
import { ExhibitorContactMode } from './exhibitorContactMode';
import { ExpoListItemDto } from './expoListItemDto';
import { ContactListItemDto } from './contactListItemDto';
import { CalendarEntryBoothDto } from './calendarEntryBoothDto';
import { InfoSlideItemDto } from './infoSlideItemDto';
import { LecternVisibility } from './lecternVisibility';
import { BoothConfigurationDto } from './boothConfigurationDto';
import { ExhibitorInfoVisibility } from './exhibitorInfoVisibility';
import { ExhibitorGroupReferenceDto } from './exhibitorGroupReferenceDto';
import { DisplayListDto } from './displayListDto';
import { ExhibitorReferenceDto } from './exhibitorReferenceDto';
import { ExhibitorContactListDto } from './exhibitorContactListDto';
import { LinkListDto } from './linkListDto';


export interface ExpoExhibitorListItemDto { 
    sceneId?: number;
    id?: string;
    shortKey?: string;
    name: string;
    company?: string;
    phoneNumber?: string;
    address?: string;
    email?: string;
    description?: string;
    descriptionLong?: string;
    categoryId?: number;
    label?: string;
    website?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    backgroundImage?: string;
    posterA?: string;
    posterB?: string;
    hideAvatar?: boolean;
    groupChatEnabled?: boolean;
    singleChatEnabled?: boolean;
    chatWelcomeMessage?: string;
    singleChatWelcomeMessage?: string;
    boothIcon?: string;
    avatar?: string;
    panels?: string;
    displays?: Array<DisplayListDto>;
    links?: Array<LinkListDto>;
    infoSlides?: Array<InfoSlideItemDto>;
    expo?: ExpoListItemDto;
    showContact?: boolean;
    contact?: ContactListItemDto;
    contacts?: Array<ExhibitorContactListDto>;
    sequence?: number;
    schedulingService?: number;
    schedulingKey?: string;
    schedulingActive?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    chatEnabled?: boolean;
    boothConfiguration?: BoothConfigurationDto;
    next?: ExhibitorReferenceDto;
    previous?: ExhibitorReferenceDto;
    stage?: StageReferenceDto;
    exhibitorGroupId?: number;
    exhibitorGroup?: ExhibitorGroupReferenceDto;
    socialProfiles?: { [key: string]: string; };
    shortLabel?: string;
    hasChatAvailabilites?: boolean;
    contactMail?: string;
    contactMode?: ExhibitorContactMode;
    exhibitorInfoVisibility?: ExhibitorInfoVisibility;
    hideFurniture?: boolean;
    hideWalls?: boolean;
    hideLogo?: boolean;
    lecternVisibility?: LecternVisibility;
    liveStreamActive?: boolean;
    liveStreamType?: StreamType;
    liveStreamId?: string;
    current?: number;
    calendarEntries?: Array<CalendarEntryBoothDto>;
    currentCalendarEntry?: CalendarEntryBoothDto;
    hasAvailabilities?: boolean;
    rowVersion?: number;
    boothPresentationEnabled?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoExhibitorListItemDto.
 */
export namespace ExpoExhibitorListItemDto {
    /**
     * All properties of ExpoExhibitorListItemDto.
     */
    export enum Properties {
        sceneId = 'sceneId',
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        company = 'company',
        phoneNumber = 'phoneNumber',
        address = 'address',
        email = 'email',
        description = 'description',
        descriptionLong = 'descriptionLong',
        categoryId = 'categoryId',
        label = 'label',
        website = 'website',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        backgroundImage = 'backgroundImage',
        posterA = 'posterA',
        posterB = 'posterB',
        hideAvatar = 'hideAvatar',
        groupChatEnabled = 'groupChatEnabled',
        singleChatEnabled = 'singleChatEnabled',
        chatWelcomeMessage = 'chatWelcomeMessage',
        singleChatWelcomeMessage = 'singleChatWelcomeMessage',
        boothIcon = 'boothIcon',
        avatar = 'avatar',
        panels = 'panels',
        displays = 'displays',
        links = 'links',
        infoSlides = 'infoSlides',
        expo = 'expo',
        showContact = 'showContact',
        contact = 'contact',
        contacts = 'contacts',
        sequence = 'sequence',
        schedulingService = 'schedulingService',
        schedulingKey = 'schedulingKey',
        schedulingActive = 'schedulingActive',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        chatEnabled = 'chatEnabled',
        boothConfiguration = 'boothConfiguration',
        next = 'next',
        previous = 'previous',
        stage = 'stage',
        exhibitorGroupId = 'exhibitorGroupId',
        exhibitorGroup = 'exhibitorGroup',
        socialProfiles = 'socialProfiles',
        shortLabel = 'shortLabel',
        hasChatAvailabilites = 'hasChatAvailabilites',
        contactMail = 'contactMail',
        contactMode = 'contactMode',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        hideFurniture = 'hideFurniture',
        hideWalls = 'hideWalls',
        hideLogo = 'hideLogo',
        lecternVisibility = 'lecternVisibility',
        liveStreamActive = 'liveStreamActive',
        liveStreamType = 'liveStreamType',
        liveStreamId = 'liveStreamId',
        current = 'current',
        calendarEntries = 'calendarEntries',
        currentCalendarEntry = 'currentCalendarEntry',
        hasAvailabilities = 'hasAvailabilities',
        rowVersion = 'rowVersion',
        boothPresentationEnabled = 'boothPresentationEnabled'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoExhibitorListItemDto.
    */
    export const ModelValidators: {[K in keyof ExpoExhibitorListItemDto]: [string, ValidatorFn][]} = {
        sceneId: [
        ],
        id: [
        ],
        shortKey: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        phoneNumber: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        address: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(500)],
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        descriptionLong: [
        ],
        categoryId: [
        ],
        label: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        website: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        backgroundImage: [
        ],
        posterA: [
        ],
        posterB: [
        ],
        hideAvatar: [
        ],
        groupChatEnabled: [
        ],
        singleChatEnabled: [
        ],
        chatWelcomeMessage: [
        ],
        singleChatWelcomeMessage: [
        ],
        boothIcon: [
        ],
        avatar: [
        ],
        panels: [
        ],
        displays: [
        ],
        links: [
        ],
        infoSlides: [
        ],
        expo: [
        ],
        showContact: [
        ],
        contact: [
        ],
        contacts: [
        ],
        sequence: [
        ],
        schedulingService: [
        ],
        schedulingKey: [
        ],
        schedulingActive: [
        ],
        googleAnalyticsId: [
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        chatEnabled: [
        ],
        boothConfiguration: [
        ],
        next: [
        ],
        previous: [
        ],
        stage: [
        ],
        exhibitorGroupId: [
        ],
        exhibitorGroup: [
        ],
        socialProfiles: [
        ],
        shortLabel: [
        ],
        hasChatAvailabilites: [
        ],
        contactMail: [
        ],
        contactMode: [
        ],
        exhibitorInfoVisibility: [
        ],
        hideFurniture: [
        ],
        hideWalls: [
        ],
        hideLogo: [
        ],
        lecternVisibility: [
        ],
        liveStreamActive: [
        ],
        liveStreamType: [
        ],
        liveStreamId: [
        ],
        current: [
        ],
        calendarEntries: [
        ],
        currentCalendarEntry: [
        ],
        hasAvailabilities: [
        ],
        rowVersion: [
        ],
        boothPresentationEnabled: [
        ],
    };

    /**
    * The FormControlFactory for ExpoExhibitorListItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoExhibitorListItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoExhibitorListItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoExhibitorListItemDto = {
            sceneId: null,
            id: null,
            shortKey: null,
            name: null,
            company: null,
            phoneNumber: null,
            address: null,
            email: null,
            description: null,
            descriptionLong: null,
            categoryId: null,
            label: null,
            website: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            backgroundImage: null,
            posterA: null,
            posterB: null,
            hideAvatar: null,
            groupChatEnabled: null,
            singleChatEnabled: null,
            chatWelcomeMessage: null,
            singleChatWelcomeMessage: null,
            boothIcon: null,
            avatar: null,
            panels: null,
            displays: null,
            links: null,
            infoSlides: null,
            expo: null,
            showContact: null,
            contact: null,
            contacts: null,
            sequence: null,
            schedulingService: null,
            schedulingKey: null,
            schedulingActive: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            chatEnabled: null,
            boothConfiguration: null,
            next: null,
            previous: null,
            stage: null,
            exhibitorGroupId: null,
            exhibitorGroup: null,
            socialProfiles: null,
            shortLabel: null,
            hasChatAvailabilites: null,
            contactMail: null,
            contactMode: null,
            exhibitorInfoVisibility: null,
            hideFurniture: null,
            hideWalls: null,
            hideLogo: null,
            lecternVisibility: null,
            liveStreamActive: null,
            liveStreamType: null,
            liveStreamId: null,
            current: null,
            calendarEntries: null,
            currentCalendarEntry: null,
            hasAvailabilities: null,
            rowVersion: null,
            boothPresentationEnabled: null,
          }
        ) {
            super(model, ExpoExhibitorListItemDto.ModelValidators);
        }
    }

}


