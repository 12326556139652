/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface VisitorProfileDetailUpdateDto { 
    name: string;
    email?: string;
    company?: string;
    role?: string;
    socialProfiles?: { [key: string]: string; };
    phoneNumber?: string;
    address?: string;
    description?: string;
    image?: string;
    imageRaw?: string;
    termsAccepted?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of VisitorProfileDetailUpdateDto.
 */
export namespace VisitorProfileDetailUpdateDto {
    /**
     * All properties of VisitorProfileDetailUpdateDto.
     */
    export enum Properties {
        name = 'name',
        email = 'email',
        company = 'company',
        role = 'role',
        socialProfiles = 'socialProfiles',
        phoneNumber = 'phoneNumber',
        address = 'address',
        description = 'description',
        image = 'image',
        imageRaw = 'imageRaw',
        termsAccepted = 'termsAccepted'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of VisitorProfileDetailUpdateDto.
    */
    export const ModelValidators: {[K in keyof VisitorProfileDetailUpdateDto]: [string, ValidatorFn][]} = {
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        email: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        company: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        role: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        socialProfiles: [
        ],
        phoneNumber: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        address: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(250)],
        ],
        description: [
        ],
        image: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        imageRaw: [
        ],
        termsAccepted: [
        ],
    };

    /**
    * The FormControlFactory for VisitorProfileDetailUpdateDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<VisitorProfileDetailUpdateDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for VisitorProfileDetailUpdateDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: VisitorProfileDetailUpdateDto = {
            name: null,
            email: null,
            company: null,
            role: null,
            socialProfiles: null,
            phoneNumber: null,
            address: null,
            description: null,
            image: null,
            imageRaw: null,
            termsAccepted: null,
          }
        ) {
            super(model, VisitorProfileDetailUpdateDto.ModelValidators);
        }
    }

}


