/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExhibitorContactDto { 
    exhibitorId?: string;
    userId?: string;
    responsibility?: string;
    showOnWebsite?: boolean;
    chatUser?: boolean;
    online?: boolean;
    videoOnline?: boolean;
    sequence?: number;
    onlineTimestamp?: Date;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorContactDto.
 */
export namespace ExhibitorContactDto {
    /**
     * All properties of ExhibitorContactDto.
     */
    export enum Properties {
        exhibitorId = 'exhibitorId',
        userId = 'userId',
        responsibility = 'responsibility',
        showOnWebsite = 'showOnWebsite',
        chatUser = 'chatUser',
        online = 'online',
        videoOnline = 'videoOnline',
        sequence = 'sequence',
        onlineTimestamp = 'onlineTimestamp'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorContactDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorContactDto]: [string, ValidatorFn][]} = {
        exhibitorId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        userId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        responsibility: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        showOnWebsite: [
        ],
        chatUser: [
        ],
        online: [
        ],
        videoOnline: [
        ],
        sequence: [
        ],
        onlineTimestamp: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorContactDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorContactDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorContactDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorContactDto = {
            exhibitorId: null,
            userId: null,
            responsibility: null,
            showOnWebsite: null,
            chatUser: null,
            online: null,
            videoOnline: null,
            sequence: null,
            onlineTimestamp: null,
          }
        ) {
            super(model, ExhibitorContactDto.ModelValidators);
        }
    }

}


