/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';


export interface LivestreamSettingsDto { 
    expoId?: string;
    exhibitorId?: string;
    streamType?: StreamType;
    streamId?: string;
}

/**
 * Namespace for property- and property-value-enumerations of LivestreamSettingsDto.
 */
export namespace LivestreamSettingsDto {
    /**
     * All properties of LivestreamSettingsDto.
     */
    export enum Properties {
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        streamType = 'streamType',
        streamId = 'streamId'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of LivestreamSettingsDto.
    */
    export const ModelValidators: {[K in keyof LivestreamSettingsDto]: [string, ValidatorFn][]} = {
        expoId: [
        ],
        exhibitorId: [
        ],
        streamType: [
        ],
        streamId: [
        ],
    };

    /**
    * The FormControlFactory for LivestreamSettingsDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<LivestreamSettingsDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for LivestreamSettingsDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: LivestreamSettingsDto = {
            expoId: null,
            exhibitorId: null,
            streamType: null,
            streamId: null,
          }
        ) {
            super(model, LivestreamSettingsDto.ModelValidators);
        }
    }

}


