/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { LecternVisibility } from './lecternVisibility';


export interface BoothDetailItemDtoAllOf { 
    exhibitorId?: string;
    expoId?: string;
    expoShortKey?: string;
    sizes?: string;
    displayMax?: number;
    linkMax?: number;
    textChatEnabled?: boolean;
    videoChatEnabled?: boolean;
    calendarEnabled?: boolean;
    infoSlideMax?: number;
    liveStreamEnabled?: boolean;
    hideFurniture?: boolean;
    hideWalls?: boolean;
    hideLogo?: boolean;
    lecternVisibility?: LecternVisibility;
    tagsExhibitorEditable?: boolean;
    boothPresentationEnabled?: boolean;
    current?: number;
}

/**
 * Namespace for property- and property-value-enumerations of BoothDetailItemDtoAllOf.
 */
export namespace BoothDetailItemDtoAllOf {
    /**
     * All properties of BoothDetailItemDtoAllOf.
     */
    export enum Properties {
        exhibitorId = 'exhibitorId',
        expoId = 'expoId',
        expoShortKey = 'expoShortKey',
        sizes = 'sizes',
        displayMax = 'displayMax',
        linkMax = 'linkMax',
        textChatEnabled = 'textChatEnabled',
        videoChatEnabled = 'videoChatEnabled',
        calendarEnabled = 'calendarEnabled',
        infoSlideMax = 'infoSlideMax',
        liveStreamEnabled = 'liveStreamEnabled',
        hideFurniture = 'hideFurniture',
        hideWalls = 'hideWalls',
        hideLogo = 'hideLogo',
        lecternVisibility = 'lecternVisibility',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        boothPresentationEnabled = 'boothPresentationEnabled',
        current = 'current'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of BoothDetailItemDtoAllOf.
    */
    export const ModelValidators: {[K in keyof BoothDetailItemDtoAllOf]: [string, ValidatorFn][]} = {
        exhibitorId: [
        ],
        expoId: [
        ],
        expoShortKey: [
        ],
        sizes: [
        ],
        displayMax: [
        ],
        linkMax: [
        ],
        textChatEnabled: [
        ],
        videoChatEnabled: [
        ],
        calendarEnabled: [
        ],
        infoSlideMax: [
        ],
        liveStreamEnabled: [
        ],
        hideFurniture: [
        ],
        hideWalls: [
        ],
        hideLogo: [
        ],
        lecternVisibility: [
        ],
        tagsExhibitorEditable: [
        ],
        boothPresentationEnabled: [
        ],
        current: [
        ],
    };

    /**
    * The FormControlFactory for BoothDetailItemDtoAllOf.
    */
    export class FormControlFactory extends BaseFormControlFactory<BoothDetailItemDtoAllOf> {

        /**
         * Constructor.
         *
         * @param model An existing model for BoothDetailItemDtoAllOf.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: BoothDetailItemDtoAllOf = {
            exhibitorId: null,
            expoId: null,
            expoShortKey: null,
            sizes: null,
            displayMax: null,
            linkMax: null,
            textChatEnabled: null,
            videoChatEnabled: null,
            calendarEnabled: null,
            infoSlideMax: null,
            liveStreamEnabled: null,
            hideFurniture: null,
            hideWalls: null,
            hideLogo: null,
            lecternVisibility: null,
            tagsExhibitorEditable: null,
            boothPresentationEnabled: null,
            current: null,
          }
        ) {
            super(model, BoothDetailItemDtoAllOf.ModelValidators);
        }
    }

}


