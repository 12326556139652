/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface InfoSlideItemDto { 
    description?: string;
    image?: string;
    link?: string;
    fontSize?: number;
    sequence?: number;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of InfoSlideItemDto.
 */
export namespace InfoSlideItemDto {
    /**
     * All properties of InfoSlideItemDto.
     */
    export enum Properties {
        description = 'description',
        image = 'image',
        link = 'link',
        fontSize = 'fontSize',
        sequence = 'sequence',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of InfoSlideItemDto.
    */
    export const ModelValidators: {[K in keyof InfoSlideItemDto]: [string, ValidatorFn][]} = {
        description: [
        ],
        image: [
        ],
        link: [
        ],
        fontSize: [
        ],
        sequence: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for InfoSlideItemDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<InfoSlideItemDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for InfoSlideItemDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: InfoSlideItemDto = {
            description: null,
            image: null,
            link: null,
            fontSize: null,
            sequence: null,
            rowVersion: null,
          }
        ) {
            super(model, InfoSlideItemDto.ModelValidators);
        }
    }

}


