/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { AccessMode } from './accessMode';
import { OverviewMode } from './overviewMode';


export interface ExpoReferenceDto { 
    id?: string;
    name?: string;
    shortKey?: string;
    accessMode?: AccessMode;
    overviewMode?: OverviewMode;
    logoSquare?: string;
    logoWide?: string;
    randomChatNames?: boolean;
    timeZone?: string;
    rowVersion?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoReferenceDto.
 */
export namespace ExpoReferenceDto {
    /**
     * All properties of ExpoReferenceDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        shortKey = 'shortKey',
        accessMode = 'accessMode',
        overviewMode = 'overviewMode',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        randomChatNames = 'randomChatNames',
        timeZone = 'timeZone',
        rowVersion = 'rowVersion'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoReferenceDto.
    */
    export const ModelValidators: {[K in keyof ExpoReferenceDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        shortKey: [
        ],
        accessMode: [
        ],
        overviewMode: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        randomChatNames: [
        ],
        timeZone: [
        ],
        rowVersion: [
        ],
    };

    /**
    * The FormControlFactory for ExpoReferenceDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoReferenceDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoReferenceDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoReferenceDto = {
            id: null,
            name: null,
            shortKey: null,
            accessMode: null,
            overviewMode: null,
            logoSquare: null,
            logoWide: null,
            randomChatNames: null,
            timeZone: null,
            rowVersion: null,
          }
        ) {
            super(model, ExpoReferenceDto.ModelValidators);
        }
    }

}


