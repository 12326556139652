/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { StreamType } from './streamType';
import { FeaturedMode } from './featuredMode';
import { CalendarEntryType } from './calendarEntryType';
import { CalendarEntryBoothDto } from './calendarEntryBoothDto';
import { CalendarEntryBoothViewDtoAllOf } from './calendarEntryBoothViewDtoAllOf';


export interface CalendarEntryBoothViewDto { 
    id: number;
    label: string;
    entryType?: CalendarEntryType;
    description?: string;
    start?: Date;
    duration?: number;
    expoId: string;
    exhibitorId: string;
    exhibitorName?: string;
    streamType?: StreamType;
    stream?: string;
    streamAdditional?: string;
    streamAdditional2?: string;
    featured?: FeaturedMode;
    exhibitorShortKey?: string;
}

/**
 * Namespace for property- and property-value-enumerations of CalendarEntryBoothViewDto.
 */
export namespace CalendarEntryBoothViewDto {
    /**
     * All properties of CalendarEntryBoothViewDto.
     */
    export enum Properties {
        id = 'id',
        label = 'label',
        entryType = 'entryType',
        description = 'description',
        start = 'start',
        duration = 'duration',
        expoId = 'expoId',
        exhibitorId = 'exhibitorId',
        exhibitorName = 'exhibitorName',
        streamType = 'streamType',
        stream = 'stream',
        streamAdditional = 'streamAdditional',
        streamAdditional2 = 'streamAdditional2',
        featured = 'featured',
        exhibitorShortKey = 'exhibitorShortKey'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of CalendarEntryBoothViewDto.
    */
    export const ModelValidators: {[K in keyof CalendarEntryBoothViewDto]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
        ],
        label: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        entryType: [
        ],
        description: [
        ],
        start: [
        ],
        duration: [
        ],
        expoId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        exhibitorId: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        exhibitorName: [
        ],
        streamType: [
        ],
        stream: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        streamAdditional: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        streamAdditional2: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        featured: [
        ],
        exhibitorShortKey: [
        ],
    };

    /**
    * The FormControlFactory for CalendarEntryBoothViewDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<CalendarEntryBoothViewDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for CalendarEntryBoothViewDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: CalendarEntryBoothViewDto = {
            id: null,
            label: null,
            entryType: null,
            description: null,
            start: null,
            duration: null,
            expoId: null,
            exhibitorId: null,
            exhibitorName: null,
            streamType: null,
            stream: null,
            streamAdditional: null,
            streamAdditional2: null,
            featured: null,
            exhibitorShortKey: null,
          }
        ) {
            super(model, CalendarEntryBoothViewDto.ModelValidators);
        }
    }

}


