/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export type InventoryItemType = 0 | 1 | 2 | 3 | 10 | 11 | 21 | 22 | 1000;

export const InventoryItemType = {
    NUMBER_0: 0 as InventoryItemType,
    NUMBER_1: 1 as InventoryItemType,
    NUMBER_2: 2 as InventoryItemType,
    NUMBER_3: 3 as InventoryItemType,
    NUMBER_10: 10 as InventoryItemType,
    NUMBER_11: 11 as InventoryItemType,
    NUMBER_21: 21 as InventoryItemType,
    NUMBER_22: 22 as InventoryItemType,
    NUMBER_1000: 1000 as InventoryItemType
};

