<a href="#" (click)="enableIntro($event)" *ngIf="introService.introEnabled" class="help-icon" [title]="translate.instant('intro.toggle')" [attr.data-intro]="translate.instant('intro.toggle')" data-step="1" data-hint="Intro">
  <i class="fa fa-2x fa-question"></i>
</a>
<app-menu></app-menu>
<app-json-ld></app-json-ld>
<ngx-spinner></ngx-spinner>
<!--<app-loading-indicator *ngIf="loading"></app-loading-indicator>-->
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-footer></app-footer>
<!-- timeStamp: '2024-04-03 08:15:42 UTC'  -->
