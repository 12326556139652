/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { VideoChatMode } from './videoChatMode';


export interface AvailableWebChatDto { 
    id?: string;
    name?: string;
    shortKey?: string;
    expoId?: string;
    expoName?: string;
    expoShortKey?: string;
    videoChatMode?: VideoChatMode;
    groupChatEnabled?: boolean;
    singleChatEnabled?: boolean;
}

/**
 * Namespace for property- and property-value-enumerations of AvailableWebChatDto.
 */
export namespace AvailableWebChatDto {
    /**
     * All properties of AvailableWebChatDto.
     */
    export enum Properties {
        id = 'id',
        name = 'name',
        shortKey = 'shortKey',
        expoId = 'expoId',
        expoName = 'expoName',
        expoShortKey = 'expoShortKey',
        videoChatMode = 'videoChatMode',
        groupChatEnabled = 'groupChatEnabled',
        singleChatEnabled = 'singleChatEnabled'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of AvailableWebChatDto.
    */
    export const ModelValidators: {[K in keyof AvailableWebChatDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        name: [
        ],
        shortKey: [
        ],
        expoId: [
        ],
        expoName: [
        ],
        expoShortKey: [
        ],
        videoChatMode: [
        ],
        groupChatEnabled: [
        ],
        singleChatEnabled: [
        ],
    };

    /**
    * The FormControlFactory for AvailableWebChatDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<AvailableWebChatDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for AvailableWebChatDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: AvailableWebChatDto = {
            id: null,
            name: null,
            shortKey: null,
            expoId: null,
            expoName: null,
            expoShortKey: null,
            videoChatMode: null,
            groupChatEnabled: null,
            singleChatEnabled: null,
          }
        ) {
            super(model, AvailableWebChatDto.ModelValidators);
        }
    }

}


