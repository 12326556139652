/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';



export interface ExhibitorContactListDto { 
    name?: string;
    email?: string;
    responsibility?: string;
    phoneNumber?: string;
    address?: string;
    image?: string;
    showOnWebsite?: boolean;
    sequence?: number;
}

/**
 * Namespace for property- and property-value-enumerations of ExhibitorContactListDto.
 */
export namespace ExhibitorContactListDto {
    /**
     * All properties of ExhibitorContactListDto.
     */
    export enum Properties {
        name = 'name',
        email = 'email',
        responsibility = 'responsibility',
        phoneNumber = 'phoneNumber',
        address = 'address',
        image = 'image',
        showOnWebsite = 'showOnWebsite',
        sequence = 'sequence'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExhibitorContactListDto.
    */
    export const ModelValidators: {[K in keyof ExhibitorContactListDto]: [string, ValidatorFn][]} = {
        name: [
        ],
        email: [
        ],
        responsibility: [
        ],
        phoneNumber: [
        ],
        address: [
        ],
        image: [
        ],
        showOnWebsite: [
        ],
        sequence: [
        ],
    };

    /**
    * The FormControlFactory for ExhibitorContactListDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExhibitorContactListDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExhibitorContactListDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExhibitorContactListDto = {
            name: null,
            email: null,
            responsibility: null,
            phoneNumber: null,
            address: null,
            image: null,
            showOnWebsite: null,
            sequence: null,
          }
        ) {
            super(model, ExhibitorContactListDto.ModelValidators);
        }
    }

}


