/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ActionButton } from './actionButton';


export interface BoothConfigurationDto { 
    boothTypeId?: number;
    colors?: Array<string>;
    actionButtonMode?: number;
    actionButtons?: Array<ActionButton>;
    groupChatLabel?: string;
    privateChatGreeting?: string;
    bannerImage?: string;
    bannerSource?: string;
    bannerLink?: string;
    bannerType?: number;
    bannerSize?: number;
    bannerDelay?: number;
}

/**
 * Namespace for property- and property-value-enumerations of BoothConfigurationDto.
 */
export namespace BoothConfigurationDto {
    /**
     * All properties of BoothConfigurationDto.
     */
    export enum Properties {
        boothTypeId = 'boothTypeId',
        colors = 'colors',
        actionButtonMode = 'actionButtonMode',
        actionButtons = 'actionButtons',
        groupChatLabel = 'groupChatLabel',
        privateChatGreeting = 'privateChatGreeting',
        bannerImage = 'bannerImage',
        bannerSource = 'bannerSource',
        bannerLink = 'bannerLink',
        bannerType = 'bannerType',
        bannerSize = 'bannerSize',
        bannerDelay = 'bannerDelay'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of BoothConfigurationDto.
    */
    export const ModelValidators: {[K in keyof BoothConfigurationDto]: [string, ValidatorFn][]} = {
        boothTypeId: [
        ],
        colors: [
        ],
        actionButtonMode: [
        ],
        actionButtons: [
        ],
        groupChatLabel: [
        ],
        privateChatGreeting: [
        ],
        bannerImage: [
        ],
        bannerSource: [
        ],
        bannerLink: [
        ],
        bannerType: [
        ],
        bannerSize: [
        ],
        bannerDelay: [
                ['min', Validators.min(0)],
                ['max', Validators.max(30)],
        ],
    };

    /**
    * The FormControlFactory for BoothConfigurationDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<BoothConfigurationDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for BoothConfigurationDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: BoothConfigurationDto = {
            boothTypeId: null,
            colors: null,
            actionButtonMode: null,
            actionButtons: null,
            groupChatLabel: null,
            privateChatGreeting: null,
            bannerImage: null,
            bannerSource: null,
            bannerLink: null,
            bannerType: null,
            bannerSize: null,
            bannerDelay: null,
          }
        ) {
            super(model, BoothConfigurationDto.ModelValidators);
        }
    }

}


