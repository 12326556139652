/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CalendarEntryStageDto } from './calendarEntryStageDto';
import { StageDto } from './stageDto';
import { ExpoExhibitorOverviewDto } from './expoExhibitorOverviewDto';


export interface ExpoStageEventDto { 
    stage?: StageDto;
    calendar?: Array<CalendarEntryStageDto>;
    exhibitors?: Array<ExpoExhibitorOverviewDto>;
    current?: CalendarEntryStageDto;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoStageEventDto.
 */
export namespace ExpoStageEventDto {
    /**
     * All properties of ExpoStageEventDto.
     */
    export enum Properties {
        stage = 'stage',
        calendar = 'calendar',
        exhibitors = 'exhibitors',
        current = 'current'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoStageEventDto.
    */
    export const ModelValidators: {[K in keyof ExpoStageEventDto]: [string, ValidatorFn][]} = {
        stage: [
        ],
        calendar: [
        ],
        exhibitors: [
        ],
        current: [
        ],
    };

    /**
    * The FormControlFactory for ExpoStageEventDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoStageEventDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoStageEventDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoStageEventDto = {
            stage: null,
            calendar: null,
            exhibitors: null,
            current: null,
          }
        ) {
            super(model, ExpoStageEventDto.ModelValidators);
        }
    }

}


