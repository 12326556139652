/*
 * Nethotline Ulli Berthold
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ActionButton } from './actionButton';


export interface StageConfigurationDto { 
    actionButtonSize?: string;
    actionButtonMode?: number;
    actionButtons?: Array<ActionButton>;
}

/**
 * Namespace for property- and property-value-enumerations of StageConfigurationDto.
 */
export namespace StageConfigurationDto {
    /**
     * All properties of StageConfigurationDto.
     */
    export enum Properties {
        actionButtonSize = 'actionButtonSize',
        actionButtonMode = 'actionButtonMode',
        actionButtons = 'actionButtons'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of StageConfigurationDto.
    */
    export const ModelValidators: {[K in keyof StageConfigurationDto]: [string, ValidatorFn][]} = {
        actionButtonSize: [
        ],
        actionButtonMode: [
        ],
        actionButtons: [
        ],
    };

    /**
    * The FormControlFactory for StageConfigurationDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<StageConfigurationDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for StageConfigurationDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: StageConfigurationDto = {
            actionButtonSize: null,
            actionButtonMode: null,
            actionButtons: null,
          }
        ) {
            super(model, StageConfigurationDto.ModelValidators);
        }
    }

}


